$orang: #ff8e00;
$orang2: #ffcc00;
$black: #232323;
$white: #fff;
$red: #D52B1E;
$red2: #ff2400;
$blue: #0039A6;
$blue2: #047bbf;
$green: #a0ca4c;

@media (min-width: 1800px) {
  .container2 {
    max-width: 1600px;
  }

  .header {
    &-logo {
      img {
        max-width: 125px;
      }
    }

    &-center {
      display: flex;
      flex-direction: row;
      align-self: center;

      &-addrw {
        padding: 0;
        width: 55%;
        padding-right: 15px;
        padding-left: 15px;

        &-text {
          width: auto;
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      &-menu {
        width: 45%;

        .punkt {
          a, span {
            margin-right: 16px;
            margin-left: 0;
          }
        }

        .vipbtn {
          .vipspis {
            //display: block
            //background: red
            top: 47.5px;
            padding-top: 39px;
          }
        }
      }
    }

    &-phones {
      padding-left: 30px;

      .mbtn {
        padding-left: 18px;
        position: relative;

        //&:before {
        //    display: block;
        //    position: absolute;
        //    content: "";
        //    width: 18px;
        //    height: 18px;
        //    left: 30px;
        //    top: 50%;
        //    transform: translateY(-50%);
        //    background: url("../img/icon/pointer2.svg") center no-repeat;
        //    background-size: contain;
        //}
      }

      &-phone {
        font-size: 18px;
      }
    }
  }

  .block1 {
    &-wraper {
      padding-top: 220px;
    }

    h1 {
      font-size: 55px;
    }

    &-utpwrap {
      bottom: 100px;
    }
  }

  .cenablock {
    &-card {
      &-abs {
        padding: 0 20px;
        bottom: 20px;

        &-text {
          font-size: 16px;
          padding: 20px;
        }

        &-btnwrap {
          margin-top: 15px;

          .mbtn {
            width: 70%;
            padding: 10px 15px;
          }

          .mbtn2 {
            width: 25%;
            margin-right: 5%;
          }
        }
      }
    }
  }

  .preimblock {
    .zag {
      padding-bottom: 10px;
    }

    &-b {
      width: 25%;
    }

    &-bots {
      margin-left: 0%;
    }

    .preim {
      width: 360px;
      height: 235px;
      padding: 70px 15px 50px 15px;

      &-zag {
        font-size: 20px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .header {
    &-logo {
      img {
        max-width: 135px;
      }
    }

    &-center {
      &-addrw {
        padding: 0;
        padding-left: 20px;

        &-text {
          width: auto;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &-menu {
        .punkt {
          a, span {
            margin-left: 7px;
            margin-right: 7px;
          }
        }
      }
    }
  }

  .block1 {
    &-wraper {
      padding-bottom: 180px;
    }

    h1 {
      padding-top: 25vh;
    }

    &-utpwrap {
      bottom: 50px;

      div {
        div {
          align-self: center;
        }
      }

      .utp {
        align-self: center;
        font-size: 16px;
        padding-left: 30px;

        &:before {
          width: 22px;
          height: 22px;
        }

        &:after {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .markiblock {
    .slick-prev {
      left: 35%;
    }

    .slick-next {
      right: 35%;
    }
  }

  .cenablock {
    .dn1200 {
      display: none;
    }
  }

  .block5 {
    .accordion {
      .info {
        .info-text-wrap {
          .info-text {
            width: 85%;
          }

          .info-btn {
            width: 25%;

            &:before {
              width: 500px;
              left: -500px;
            }
          }
        }
      }
    }
  }

  .preimblock {
    &-b {
      width: 50%;
    }

    &-bots {
      margin-left: 0;
    }

    .preim {
      width: 420px;
      height: 205px;
    }
  }

  .footer {
    &-menu {
      a {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 991px) {
  .header {
    //&-logo {
    //    img {
    //        max-width: 100%;
    //    }
    //}

    &-phones {
      display: none;
    }

    &-center {
      display: block;

      &-menu {
        position: fixed;
        width: 320px;
        background-color: $white;
        padding-top: 0;
        height: 100vh;
        top: 0;
        right: 0;
        overflow-y: auto;
        display: none;
        z-index: 10;

        .punkt {
          display: block;
          text-align: left;

          a, span {
            display: block;
            padding: 14px 25px;
            margin: 0;


            &:before {
              display: none;
            }

            &:hover {
              background: #f3f3f3;
            }
          }
        }

        .punkt-phone {
          display: block;
        }

        .active_block {
          span {
            color: $blue;
            background: #f3f3f3;
          }

          span:after {
            transition: all 0.5s;
            filter: grayscale(0);
            transform: translateY(-50%) rotate(90deg);
          }

          &:hover {
            &:after {
              transition: all 0.5s;
              filter: grayscale(0);
              transform: translateY(-50%) rotate(90deg);
            }
          }
        }

        .mobmenu-close-wrap {
          display: block;
          text-align: right;
          padding-right: 20px;
          cursor: pointer;
          padding-top: 20px;
          padding-bottom: 10px;

          .mobmenu-close {
            display: inline-block;
          }
        }

        .vipbtn {
          &-ssil {
            &:after {
              left: 90px;
            }
          }

          .vipspis {
            position: static;
            display: none;
            padding: 0;

            .row {
              margin: 0;
            }

            &-wrap {
              padding: 0;
              box-shadow: none;

              .spis-block {
                padding: 0;
                text-transform: none;
                margin: 0;

                a {
                  text-transform: none;
                  font-size: 16px;
                  margin: 0px;
                  display: block;
                  width: 100%;
                  padding: 14px 10px 14px 30px;

                  &:after {
                    display: none;
                  }
                }
              }
            }
          }

          &:hover {
            .vipspis {
              display: none;

              &-wrap {
                opacity: auto;
              }
            }
          }
        }
      }
    }

    .mobmenu-open {
      display: block;
      align-self: center;
      text-align: right;
      cursor: pointer;
    }

    .menu-ten {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.7);
      min-height: 100vh;
      min-width: 100%;
      z-index: 9;
    }
  }

  .block1 {
    &-wraper {
      padding-bottom: 220px;
      padding-top: 100px;
    }

    h1 {
      padding-top: 20vh;
      font-size: 35px;
    }

    &-btnwrap {
      margin-top: 50px;

      .mbtn {
        padding: 15px 18px;
        width: auto;
        margin: 0;
        font-size: 12px;
      }

      .mbtnot {
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &-utpwrap {
      bottom: 60px;

      .utp {
        align-self: center;
        font-size: 17px;
        padding-left: 45px;
        width: 340px;
        margin-bottom: 20px;
        display: block;
        text-align-last: left;
        margin-left: auto;
        margin-right: auto;

        &:before {
          width: 30px;
          height: 30px;
        }

        &:after {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .markiblock {
    .slick-prev {
      left: 30%;
    }

    .slick-next {
      right: 30%;
    }
  }

  .cenablock {
    .dn990 {
      display: none;
    }
  }

  .block5 {
    .accordion {
      .info {
        .info-text-wrap {
          .info-text {
            width: 72%;
          }

          .info-btn {
            width: 28%;
            text-align: right;

            &:before {
              width: 200px;
              left: -200px;
            }

            .mbtn2 {
              margin-right: 45px;
            }
          }
        }
      }
    }
  }

  .preimblock {
    &-b {
      width: 50%;
    }

    &-bots {
      margin-left: 0;
    }

    .preim {
      width: 310px;
      height: 205px;
    }
  }

  .footer {
    &-logo {
      max-width: 200px;
    }

    &-copyright {
      margin-bottom: 20px;
    }

    &-menu {
      margin-bottom: 10px;

      a {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
  }
}

@media(max-width: 992px) {
  .header {
    &-addrw {
      position: absolute;
      top: 65px;
      display: none;
      overflow-y: auto;
      padding: 0;
      margin: 0;
      background: $white;
      padding-bottom: 30px;
      padding-top: 20px;
      overflow: hidden;

      &-text {
        padding: 0;
        margin: 0;
        margin-top: 25px;
        position: relative;
        margin-right: 30px;
        margin-left: 30px;

        &-zag {
          font-size: 20px;
          padding-bottom: 10px;
        }

        span {
          font-size: 18px;
        }

        a {
          font-size: 20px;
          padding-top: 10px;
        }
      }

      &-close {
        display: block;
        cursor: pointer;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -4px;
        top: -45px;
        background: url("../img/icon/close-black.svg") center no-repeat;
        background-size: cover;
      }
    }

    &-center {
      width: 0.1px;
      height: 0.1px;
      background: transparent;
      position: absolute;
      left: 0;
      top: 0;

      &-menu {
        display: none;
        overflow-y: auto;
        min-height: 100vh;
        height: 100vh;

        .punkt-phone {
          display: none;
        }
      }


    }

    &-phones {
      display: block;

      .mbtn {
        display: none;
      }

      &-phone {
        font-size: 20px;
        margin-top: 0;
        padding-top: 0;
      }

      .addr-open {
        display: block;
        font-weight: 500;
        text-align: center;
        font-size: 17px;
        padding-top: 5px;

        span {
          padding-left: 22px;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            width: 20px;
            height: 15px;
            background: url("../img/icon/addr-str.svg") center center no-repeat;
            background-size: contain;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .block1 {
    &-wraper {
      padding-bottom: 250px;
      padding-top: 80px;
    }

    h1 {
      padding-top: 15vh;
      font-size: 34px;
      letter-spacing: 0;
    }

    &-btnwrap {
      .mbtnot {
        margin: 0;
      }

      .mbtn {
        padding: 23px 30px;
        width: 360px;
        margin: 0;
        font-size: 14px;
        margin-bottom: 20px;
        box-sizing: border-box;
      }

      &-underprice {
        .mbtn {
          margin: 0 auto 20px auto;
        }
      }
    }

    &-utpwrap {
      bottom: 60px;

      .utp {
        align-self: center;
        font-size: 17px;
        padding-left: 45px;
        width: 320px;
        margin-bottom: 20px;
        display: block;
        text-align-last: left;
        margin-left: auto;
        margin-right: auto;

        &:before {
          width: 30px;
          height: 30px;
        }

        &:after {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .markiblock {
    .slick-prev {
      left: 23%;
      bottom: -60px;
    }

    .slick-next {
      right: 23%;
      bottom: -60px;
    }

    .btn-wrap {
      margin-top: -15px;
    }
  }

  .cenablock {
    &-card {
      height: 240px;

      &-abs {
        &-btnwrap {
          padding-right: 0;
          padding-left: 0;

          .mbtn {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .block5 {
    .accordion {
      .title_block {
        padding: 10px 20px;

        &:before {
          right: 20px;
        }
      }

      .info {
        .info-text-wrap {
          .info-text {
            width: 70%;
            margin-left: 40px;
            font-size: 14px;

            span {
              &:after {
                width: 14px;
                height: 14px;
                left: -20px;
              }

              &:before {
                width: 14px;
                height: 14px;
                left: -20px;
              }
            }
          }

          .info-btn {
            font-size: 14px;
            width: 30%;

            .mbtn2 {
              font-size: 14px;
              margin-right: 35px;

              &:after {
                width: 20px;
                height: 20px;
                right: -15px;
              }
            }
          }
        }
      }
    }
  }

  .preimblock {
    &-b {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    .preim {
      width: 360px;
      max-width: 100%;
      height: auto;
      min-height: 200px;
    }
  }

  .cartablock {
    &-card {
      div {
        margin-left: auto;
        margin-right: auto;
        max-width: 320px;
      }
    }
  }
}

@media(max-width: 575px) {
  .mslider {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;

    .slick-next {
      width: 30px;
      right: 0;
    }

    .slick-prev {
      width: 30px;
      left: 0;
    }
  }

  .header {
    padding-right: 15px;
    padding-left: 15px;

    &-logo {
      width: 30%;
      max-width: 30%;
      flex: 0 0 30%;
      padding: 0;

      img {
        max-width: 120px;
      }
    }

    &-phones {
      width: 60%;
      max-width: 60%;
      flex: 0 0 60%;
      padding: 0;
    }

    .mobmenu-open {
      width: 10%;
      max-width: 10%;
      flex: 0 0 10%;
      padding: 0;
    }
  }

  .block1 {
    &-wraper {
      padding-bottom: 60px;
      padding-top: 140px;
    }

    h1 {
      padding-top: 0;
      font-size: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &-btnwrap {
      .mbtn {
        padding: 18px 30px;
        width: 320px;
        box-sizing: border-box;
      }
    }

    &-utpwrap {
      position: static;
      margin-top: 70px;
    }
  }

  .markiblock {
    .slick-prev {
      left: 10%;
      bottom: -75px;
    }

    .slick-next {
      right: 10%;
      bottom: -75px;
    }

    .btn-wrap {
      margin-top: 0;
    }
  }

  .cenablock {
    &-card {
      height: 240px;
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .akciiblock {
    &-slider {
      max-width: 440px;
      margin-left: auto;
      margin-right: auto;
      padding-right: 40px;
      padding-left: 40px;

      &-s {
        padding: 0;

        &-card {
        }
      }
    }

    .slick-next {
      width: 30px;
      right: 0;
    }

    .slick-prev {
      width: 30px;
      left: 0;
    }
  }

  .rabotiblock {
    &-slider {
      &-s {
        padding: 0;
      }
    }
  }

  .block5 {
    .accordion {
      .title_block {
        padding: 10px 20px;

        &:before {
          right: 15px;
        }
      }

      .info {
        .info-text-wrap {
          .info-text {
            width: 70%;
            margin-left: 20px;

            span {
              &:after {
                display: none;
              }

              &:before {
                display: none;
              }
            }
          }

          .info-btn {
            .mbtn2 {
              font-size: 14px;
              margin-right: 30px;

              &:after {
                width: 15px;
                height: 15px;
                right: -10px;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    &-logo {
      max-width: 100%;
    }

    &-copyright {
      br {
        display: none;
      }
    }

    &-menu {
      a {
      }
    }
  }
}

@media (max-width: 466px) {
  .block5 {
    .accordion {
      .title_block {
        font-size: 16px;
        padding: 12px 15px;
        height: 44px;
      }

      .info {
        .info-text-wrap {
          .info-text {
            width: 87%;
          }

          .info-btn {
            width: 13%;
            margin-right: 0;

            &:before {
              display: none;
            }

            .mbtn2 {
              font-size: 0.00001px;
              color: $white;
              margin-right: 0;

              &:after {
                right: auto;
                left: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media(max-width: 400px) {
  .zag {
    font-size: 24px;
  }

  .mslider {
    padding-left: 30px;
    padding-right: 30px;

    .slick-next {
      width: 25px;
      height: 100%;
      right: -5px;
    }

    .slick-prev {
      width: 25px;
      height: 100%;
      left: -5px;
    }
  }

  .header {
    padding-top: 3px;
    padding-bottom: 3px;

    &-logo {
      img {
        max-width: 110px;
      }
    }

    &-phones {
      &-phone {
        font-size: 16px;
        text-align: right;
        padding-right: 15px;
      }

      .addr-open {
        text-align: right;
        padding-right: 15px;
        padding-top: 4px;
      }
    }
  }

  .block1 {
    &-wraper {
      padding-bottom: 50px;
    }

    &-btnwrap {
      .mbtn {
        width: 95%;
      }
    }

    &-utpwrap {
      position: static;
      margin-top: 70px;

      .utp {
        width: 100%;
      }
    }
  }

  .markiblock {
    .slick-prev {
      left: 5%;
      bottom: -85px;
    }

    .slick-next {
      right: 5%;
      bottom: -85px;
    }

    .btn-wrap {
      margin-top: 10px;
    }
  }

  .akciiblock {
    &-slider {
      padding-left: 30px;
      padding-right: 30px;

      &-s {
        padding: 0;

        &-card {
          padding: 15px;

          &-zag {
            font-size: 17px;
          }

          &-text {
            padding-left: 15px;
            padding-right: 15px;
            font-size: 15px;
          }

          .dual {
            padding-left: 15px;
            padding-right: 15px;

            &-r {
              .mbtn {
                padding: 10px 20px;
              }
            }

            &-l {
              .cena {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .slick-next {
      width: 25px;
      height: 100%;
      right: -5px;
    }

    .slick-prev {
      width: 25px;
      height: 100%;
      left: -5px;
    }
  }

  .block5 {
    .accordion {
      .info {
        .info-text-wrap {
          .info-text {
            font-size: 14px;
            padding-right: 10px;
          }

          .info-btn {
            .mbtn2 {
              &:after {
                width: 17px;
                height: 17px;
                left: -2px;
              }
            }
          }
        }
      }
    }
  }
}

@media(max-width: 365px) {
  .mslider {
    padding-left: 20px;
    padding-right: 20px;

    .slick-next {
      width: 20px;
      right: -10px;
    }

    .slick-prev {
      width: 20px;
      left: -10px;
    }
  }

  .header {
    padding-top: 3px;
    padding-bottom: 3px;

    &-logo {
      img {
        max-width: 100px;
      }
    }

    &-phones {
      &-phone {
        font-size: 16px;
        padding-right: 12px;

        span {
          &:before {
            display: none;
          }
        }
      }

      .addr-open {
        text-align: right;
        padding-right: 12px;
        padding-top: 4px;
        font-size: 15px;
      }
    }

    &-center {
      &-addrw {
        top: 69px;
      }
    }
  }

  .block1 {
    h1 {
      font-size: 26px;
    }

    &-utpwrap {
      .utp {
        font-size: 15px;
        padding-left: 45px;

        &:before {
          width: 25px;
          height: 25px;
          left: 7px;
        }

        &:after {
          width: 25px;
          left: 7px;
          height: 25px;
        }
      }
    }
  }

  .markiblock {
    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }
  }

  .akciiblock {
    &-slider {
      padding-left: 20px;
      padding-right: 20px;
    }

    .slick-next {
      width: 20px;
      right: -10px;
    }

    .slick-prev {
      width: 20px;
      left: -10px;
    }
  }

  .block5 {
    .accordion {
      .info {
        .info-text-wrap {
          .info-text {
            font-size: 12.5px;
            margin-left: 15px;
            padding-right: 5px;
          }

          .info-btn {
            .mbtn2 {
              &:after {
                width: 15px;
                height: 15px;
                left: 0px;
              }
            }
          }
        }
      }
    }
  }
}