/*
23.11.2020
File: slick.scss
Encoding: UTF-8
Project: RMS spetial for Quality Motors team

Author: Gafuroff Alexandr 
E-mail: gafuroff.al@yandex.ru
*/
/* 
    Created on : 23.11.2020, 21:07:37
    Author     : Александр
*/

/* Slider */
/* Dots */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    .slick-track {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:before {
        display: table;
        content: '';
    }
    &:after {
        display: table;
        content: '';
        clear: both;
    }
}
.slick-loading {
    .slick-track {
        visibility: hidden;
    }
    .slick-slide {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    img {
        display: block;
    }
}
[dir='rtl'] {
    .slick-slide {
        float: right;
    }
}
.slick-slide.slick-loading {
    img {
        display: none;
    }
}
.slick-slide.dragging {
    img {
        pointer-events: none;
    }
}
.slick-initialized {
    .slick-slide {
        display: block;
    }
}
.slick-vertical {
    .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev {
    width: 40px;
    height: 40px;
    position: absolute;
    cursor: pointer;
    display: block;
    transition: opacity .2s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    text-indent: -9999px;
    z-index: 7;
    border: none !important;
    outline: none !important;
    background: url(../img/icon/slide-left-blue.svg) center no-repeat;
    left: 0px;
    background-size: contain;
}
.slick-next {
    width: 40px;
    height: 40px;
    position: absolute;
    cursor: pointer;
    display: block;
    transition: opacity .2s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    text-indent: -9999px;
    z-index: 7;
    border: none !important;
    outline: none !important;
    background: url(../img/icon/slide-right-blue.svg) center no-repeat;
    right: 0px;
    background-size: contain;
}
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 0 4px;
        padding: 0;
        cursor: pointer;
        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 16px;
            height: 16px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            &:hover {
                outline: none;
                &:before {
                    opacity: 1;
                }
            }
            &:focus {
                outline: none;
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                font-family: 'slick';
                position: absolute;
                top: 0;
                left: 0;
                width: 8px;
                height: 8px;
                background: #C4C4C4;
                border-radius: 50%;
                content: '';
                text-align: center;
                opacity: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
    li.slick-active {
        button {
            &:before {
                background: #C62223;
            }
        }
    }
}
