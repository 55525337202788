@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=cyrillic,cyrillic-ext");

$orang: #ff8e00;
$orang2: #ffcc00;
$black: #232323;
$white: #fff;
$red: #D52B1E;
$red2: #ff2400;
$blue: #0039A6;
$blue2: #047bbf;
$lightblue2: #61C1FC;
$green: #a0ca4c;

@import "./bootstrap/bootstrap.scss";
@import "./bootstrap/bootstrap-grid.scss";

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: 0;
  font-family: "Roboto", sans-serif;
}

.liveinternet {
  display: block;
  height: 0px;
  width: 0px;
  overflow: hidden;
}

a {
  color: $black;
  text-decoration: none;
  transition: all 0.5s;

  &:hover {
    text-decoration: none;
    color: $black;
    transition: all 0.5s;
  }
}

body {
  color: $black;
  background-color: $white;
  font-size: 14px;
  //min-height: 5000px;
}

img {
  max-width: 100%;
}

.mbtn {
  background-color: $blue;
  color: $white;
  border-radius: 50px;
  text-align: center;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 10px 30px;

  &:hover {
    opacity: 0.9;
    color: $white;
  }
}

.mbtng {
  background-image: linear-gradient(to right, $blue 0%, $green 100%);
  border-radius: 50px;
  transition: all 0.5s;

  &:hover {
    background-image: linear-gradient(to right, $blue 0%, $green 100%);
    color: $white;
    box-shadow: 0px 4px 10px 2px $blue;
    opacity: 1;
    border: none;
    transition: all 0.5s;
  }
}

.mbtn2 {
  border: 1px solid $blue;
  color: $blue;
  transition: all 0.5s;
  background: transparent;

  &:hover {
    background: $blue;
    color: $white;
    box-shadow: 0px 4px 10px 2px $blue;
    opacity: 1;
    transition: all 0.5s;
  }
}

.mbtn2g {
  &:hover {
    transition: all 0.5s;
    background-image: linear-gradient(to right, $red2 0%, $blue 100%);
  }
}

.block {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mslider {
  .slick-next {
    right: -40px;
  }

  .slick-prev {
    left: -40px;
  }
}

.zag {
  font-size: 32px;
  text-align: center;
  font-weight: 600;
  padding-bottom: 30px;
}

.header {
  padding-top: 5px;
  padding-bottom: 4px;
  background: #fff;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;

  &-logo {
    img {
      max-width: 135px;
    }
  }

  &-center {
    display: flex;
    flex-direction: column-reverse;

    &-schedule {
      font-size: 12px;
      width: max-content;
      text-align: center;
      margin: auto;
    }

    &-addrw {
      display: flex;
      flex-wrap: wrap;
      padding-left: 70px;
      padding-right: 70px;
      justify-content: space-around;

      &-close {
        display: none;
      }

      &-text {
        width: 33.33%;
        text-align: center;

        &-zag {
          font-weight: bold;
          font-size: 14px;
          line-height: 1;
          padding-bottom: 5px;
        }

        span {
          display: block;
          font-size: 12px;
          line-height: 1;
        }

        a {
          font-weight: bold;
          display: block;
          color: $black;
          font-size: 14px;
          line-height: 1;
          padding-top: 6px;
        }
      }
    }

    &-menu {
      text-align: center;
      padding-top: 10px;

      .punkt {
        display: inline-block;

        a, span {
          display: inline-block;
          font-size: 16px;
          color: $black;
          margin-left: 13px;
          margin-right: 13px;
          position: relative;
          transition: all 0.5s;
          cursor: pointer;


          &:hover {
            transition: all 0.5s;
            text-decoration: none;
            color: $blue;

            &:before {
              transform: rotateX(0);
              transition: all 0.5s;
              width: 100%;
            }
          }
        }
      }

      .punkt-phone {
        display: none;
      }

      .mobmenu-close-wrap {
        display: none;
      }

      .vipbtn {
        .vipspis {
          position: fixed;
          width: 100%;
          left: 0;
          top: 81px;

          //background: blue
          padding-top: 14px;
          display: none;

          &-wrap {
            background: $white;
            border-radius: 5px;
            padding: 20px;
            padding-top: 40px;
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          }

          &-wrap {
            .spis-block {
              text-align: left;
              padding-left: 40px;
            }

            a {
              text-transform: uppercase;
              display: inline-block;
              margin-bottom: 20px;
              font-size: 18px;
              margin-left: 70px;
              position: relative;

              &:after {
                position: absolute;
                content: "";
                width: 30px;
                height: 30px;
                left: -50px;
                top: 50%;
                transform: translateY(-50%);
                background: url("../img/icon/brakerepair.svg") center no-repeat;
                background-size: contain;
                filter: grayscale(1);
                transition: all 0.5s;
              }

              &:hover {
                &:after {
                  filter: grayscale(0);
                  transition: all 0.5s;
                }
              }
            }

            .tehnicheskoe_obsluzhivanie:after {
              background: url("/img/icon/tehnobslug.svg") center no-repeat;
              background-size: contain;
            }

            .remont_transmissii:after {
              background: url("/img/icon/transmisia.svg") center no-repeat;
              background-size: contain;
            }

            .remont_dvigatelia:after {
              background: url("/img/icon/dvigatel.svg") center no-repeat;
              background-size: contain;
            }

            .remont_elektrooborudovaniia:after {
              background: url("/img/icon/electroob.svg") center no-repeat;
              background-size: contain;
            }

            .remont_rulevogo_upravleniia:after {
              background: url("/img/icon/rul.svg") center no-repeat;
              background-size: contain;
            }

            .moika_himchistka_polirovka_avtomobilia:after {
              background: url("/img/icon/pulirovka.svg") center no-repeat;
              background-size: contain;
            }

            .diagnostika_avtomobilia:after {
              background: url("/img/icon/diagnostick.svg") center no-repeat;
              background-size: contain;
            }

            .remont_transmissii:after {
              background: url("/img/icon/corobka.svg") center no-repeat;
              background-size: contain;
            }

            .kuzovnoi_remont:after {
              background: url("/img/icon/cusremont.svg") center no-repeat;
              background-size: contain;
            }

            .remont_hodovoi_chasti_podveski_avtomobilia:after {
              background: url("/img/icon/remhod.svg") center no-repeat;
              background-size: contain;
            }

            .remont_tormoznoi_sistemy:after {
              background: url("/img/icon/tormoz.svg") center no-repeat;
              background-size: contain;
            }

            .besteklo:after {
              background: url("/img/icon/steklo.svg") center no-repeat;
              background-size: contain;
            }

            .remont_kondicionera:after {
              background: url("/img/icon/condicioner.svg") center no-repeat;
              background-size: contain;
            }

            .remont_toplivnoi__sistemy:after {
              background: url("/img/icon/benzobak.svg") center no-repeat;
              background-size: contain;
            }

            .remont_sistemy_ohlazhdeniia:after {
              background: url("/img/icon/sysohl.svg") center no-repeat;
              background-size: contain;
            }

            .remont_vyhlopnoi__sistemy:after {
              background: url("/img/icon/vihlop.svg") center no-repeat;
              background-size: contain;
            }

            .pokraska_avtomobilia:after {
              background: url("/img/icon/pokraska.svg") center no-repeat;
              background-size: contain;
            }

            .shinomontazh:after {
              background: url("/img/icon/shinomontag.svg") center no-repeat;
              background-size: contain;
            }

            .zapchasti:after {
              background: url("/img/icon/zapchasty_bl.svg") center no-repeat;
              background-size: contain;
            }

            .argonnaia_svarka:after {
              background: url("/img/icon/welding_bl.svg") center no-repeat;
              background-size: contain;
            }
          }
        }

        &-ssil {
          padding-right: 14px;
          position: relative;


        }

        &:hover {
          .vipspis {
            display: block;
            transition: all 0.5s;
            z-index: 9;

            &-wrap {
              transition: all 0.5s;
              opacity: 0.9;
            }
          }

          .vipbtn-ssil {
            color: $blue;

            &:after {
              transition: all 0.5s;
              filter: grayscale(0);
              transform: translateY(-50%) rotate(90deg);
            }

            &:before {
              transform: rotateX(0);
              transition: all 0.5s;
              width: 100%;
            }
          }
        }
      }
    }
  }

  &-phones {
    align-self: center;

    .mbtn {
      width: 100%;
      display: block;
      padding: 10px;

      &:before {
        display: none;
      }

      &:hover {
        color: $white;
      }
    }

    &-phone {
      text-align: center;
      font-weight: bold;
      display: block;
      font-size: 16px;
      line-height: 1;
      margin-top: 10px;
      transition: all 0.5s;

      span {
        position: relative;
        display: inline-block;
        padding-left: 27px;

        &:before {
          content: "";
          position: absolute;
          left: 7px;
          top: 45%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          background: url("../img/icon/phone-blue.svg") center no-repeat;
          background-size: contain;
          filter: grayscale(1);
          transition: all 0.5s;
        }
      }

      &:hover {
        color: $blue;
        transition: all 0.5s;

        span {
          &:before {
            filter: grayscale(0);
            transition: all 0.5s;
          }
        }
      }
    }
  }

  .addr-open {
    display: none;
  }

  .mobmenu-open {
    display: none;
  }

  .menu-ten {
    display: none;
  }
}

.header-v2 {
  background: $white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.block1 {
  //background: url("../img/2.jpg") center no-repeat;
  background-size: cover;
  color: $white;
  overflow: hidden;

  &-wraper {
    padding-top: 180px;
    min-height: 83vh;
    background: rgba(0, 0, 0, 0.5);
    padding-bottom: 220px;
    position: relative;
  }

  h1 {
    font-size: 40px;
    text-align: center;
    font-weight: 800;
    color: $white;
    letter-spacing: 2px;
  }

  &-btnwrap {
    margin-top: 80px;
    text-align: center;

    &-underprice {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .mbtn {
      text-transform: uppercase;
      padding: 18px 30px;
      font-weight: 700;
      margin: 10px;
      width: 280px;
      transition: all 0.5s;
      letter-spacing: 0.5px;
      font-size: 14px;
      position: static;
      background-image: linear-gradient(to right, $blue 0%, $green 100%);
      border-radius: 50px;

      &:hover {
        background-image: linear-gradient(to right, $blue 0%, $green 100%);
        color: $white;
        box-shadow: 0px 4px 10px 2px $blue;
        opacity: 1;
        border-color: $blue;
        transition: all 0.5s;
      }
    }

    .mbtn2 {
      background: transparent;
      color: $white;
      border: 2px solid $blue;
      box-sizing: border-box;
    }

    .mbtn2r {
      border: 2px solid $green;
    }
  }

  &-utpwrap {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 60px;
    right: 0;

    .utp {
      font-weight: 700;
      font-size: 18px;
      padding-left: 55px;
      position: relative;
      display: inline;

      &:before {
        width: 37px;
        height: 37px;
        position: absolute;
        content: "";
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: linear-gradient(to right, $blue 0%, $green 100%);
      }

      &:after {
        width: 37px;
        height: 37px;
        position: absolute;
        content: "";
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("../img/icon/garant4.svg") center center no-repeat;
        background-size: 65%;
      }
    }

    /*.utp2
     * &:after
     * 	background: url('../img/icon/sale2.svg') center center no-repeat
     * 	background-size: 70% */
  }
}

.markiblock {
  margin-top: 60px;
  margin-bottom: 60px;

  &-card {
    display: block;
    text-align: center;
    padding: 5px;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &-img {
      display: block;

      img {
        max-width: 100%;
        margin: auto;
      }
    }

    &-zag {
      display: block;
      font-size: 16px;
      color: $black;
      padding-top: 5px;
      transition: all 0.5s;
    }

    &:hover {
      .markiblock-card-zag {
        color: $blue2;
        transition: all 0.5s;
      }
    }
  }

  &-slider {
    position: relative;
    margin-bottom: 0px;

    .markiblock-card {
      transition: all 0.5s;
      padding: 10px;
    }
  }

  .slick-prev {
    width: 50px;
    height: 50px;
    background: $blue;
    border-radius: 50%;
    bottom: -75px;
    left: 37%;
    top: auto;
    transition: all 0.5s;

    &:before {
      position: absolute;
      content: "";
      width: 50px;
      height: 50px;
      background: url("../img/icon/slide-left-white.svg") center no-repeat;
      background-size: 25%;
      top: 0;
      left: 0;
      transition: all 0.5s;
    }

    &:hover {
      transition: all 0.5s;
      opacity: 0.8;

      &:before {
        background-size: 35%;
        transition: all 0.5s;
      }
    }
  }

  .slick-next {
    width: 50px;
    height: 50px;
    background: $blue;
    border-radius: 50%;
    bottom: -75px;
    top: auto;
    right: 37%;

    &:before {
      position: absolute;
      content: "";
      width: 50px;
      height: 50px;
      background: url("../img/icon/slide-right-white.svg") center no-repeat;
      background-size: 25%;
      top: 0;
      left: 0;
      transition: all 0.5s;
    }

    &:hover {
      transition: all 0.5s;
      opacity: 0.8;

      &:before {
        background-size: 35%;
        transition: all 0.5s;
      }
    }
  }

  &-spis {
    display: none;
  }

  .btn-wrap {
    text-align: center;

    .mbtn {
      padding-bottom: 0;
      padding-top: 0;
      line-height: 48px;
      height: 48px;
      font-size: 15px;

      .markiblock-btn-s2 {
        display: none;
      }
    }
  }
}

.cenablock {
  padding-top: 30px;

  &-card {
    color: $white;
    background-color: $black;
    background: url("/img/services/0.jpg") center no-repeat;
    background-size: cover;
    height: 255px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 30px;

    &-ten {
      padding: 10px;
      background: rgba(0, 0, 0, 0.4);
      height: 100%;
    }

    &-abs {
      position: absolute;
      left: 0px;
      bottom: 15px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;

      &-text {
        background: rgba(0, 0, 0, 0.6);
        padding: 15px;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        display: block;

        & > a {
          color: $white;

          &:hover {
            color: $white;
          }
        }
      }

      &-btnwrap {
        margin-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-wrap: wrap-reverse;
        display: none;

        .mbtn {
          padding: 7px 10px;
          font-size: 13px;
          display: inline-block;
          width: 100%;
          margin-bottom: 10px;
        }

        .mbtn2 {
          color: $white;
          border-color: $red;

          &:hover {
            border-color: $blue;
          }
        }
      }
    }
  }

  &-pokwrap {
    text-align: center;
  }

  &-pokbtn {
    padding: 15px 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .cenablockdn {
    display: none;
  }

  .dn1200, .dn990 {
    display: block;
  }

  &-kapitalnyj-remont-dvigatelya {
    background: url("/img/services/kapitalnyj-remont-dvigatelya.jpeg") center no-repeat;
    background-size: cover;
  }

  &-diagnostika-dvigatelya {
    background: url('/img/services/diagnostika-dvigatelya.jpg') center no-repeat;
    background-size: cover;
  }

  &-tekhnicheskoe-obsluzhivanie-dvigatelya {
    background: url("/img/services/tekhnicheskoe-obsluzhivanie-dvigatelya.jpg") center no-repeat;
    background-size: cover;
  }

  &-remont-dizelnogo-dvigatelya {
    background: url("/img/services/remont-dizelnogo-dvigatelya.jpg") center no-repeat;
    background-size: cover;
  }

  &-remont-forsunki {
    background: url("/img/services/remont-forsunki.jpg") center no-repeat;
    background-size: cover;
  }

  &-remont-tnvd {
    background: url("/img/services/remont-tnvd.jpg") center no-repeat;
    background-size: cover;
  }

  &-diagnostika_avtomobilia {
    background: url("/img/services/1.jpg") center no-repeat;
    background-size: cover;
  }

  &-remont-turbiny {
    background: url("/img/services/remont-turbiny.jpg") center no-repeat;
    background-size: cover;
  }

  &-regulirovka-klapanov {
    background: url("/img/services/regulirovka-klapanov.jpg") center no-repeat;
    background-size: cover;
  }

  &-remont_tormoznoi_sistemy {
    background: url("/img/services/9.jpg") center no-repeat;
    background-size: cover;
  }

  &-remont-toplivnoy-sistemy {
    background: url("/img/services/remont-toplivnoj-sistemy.jpg") center no-repeat;
    background-size: cover;
  }

  &-promyvka-sistem-avtomobilya {
    background: url("/img/services/promyvka-sistem-avtomobilya.jpg") center no-repeat;
    background-size: cover;
  }

  &-remont-sistemy-ohlazhdeniya {
    background: url("/img/services/remont-sistemy-ohlazhdeniya.jpeg") center no-repeat;
    background-size: cover;
  }

  &-remont-gbc-dvigatelya {
    background: url("/img/services/remont-gbc-dvigatelya.jpg") center no-repeat;
    background-size: cover;
  }
}

.important {
  border-left: 4px solid #0039A6;
  padding-left: 6px;
}

.underprice {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.05rem;

  & .mbtn {
    height: 30px;
    padding: 3px 30px;
    margin-left: 50px;
  }
}

.akciiblock {
  //background: url("../img/fon_akcii.jpg") center center;
  margin-top: 60px;
  overflow-x: hidden;

  &-wrap {
    padding-top: 60px;
    padding-bottom: 90px;
    background: rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .zag {
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &-slider {
    &-s {
      padding: 15px;

      &-card {
        background: rgba($white, 0.9);
        padding: 20px;
        height: 280px;
        width: 100%;
        position: relative;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;

        &-zag {
          font-weight: 600;
          font-size: 18px;
        }

        &-text {
          font-size: 16px;
          position: absolute;
          left: 0px;
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          top: 45%;
          transform: translateY(-50%);
        }

        .dual {
          display: flex;
          flex-wrap: wrap;
          padding-right: 20px;
          padding-left: 20px;
          position: absolute;
          bottom: 20px;
          left: 0;
          width: 100%;

          &-l {
            width: 50%;
            align-self: center;

            .cena {
              color: $red;
              font-weight: 600;
              font-size: 20px;
              text-transform: inherit;
            }
          }

          &-r {
            width: 50%;
            text-align: right;
            align-self: center;
          }
        }
      }
    }
  }

  .slick-next {
    right: 70px;
    background: url("../img/icon/slide-right-white.svg") center no-repeat;
    background-size: contain;
  }

  .slick-prev {
    left: 70px;
    background: url("/img/icon/slide-left-white.svg") center no-repeat;
    background-size: contain;
  }
}

.rabotiblock {
  margin-top: 60px;

  &-slider {
    &-s {
      padding: 20px;

      img {
        cursor: pointer;
      }
    }
  }
}

.block5 {
  .accordion {
    .accordion_item {
      margin-bottom: 0px;
      position: relative;
    }

    .title_block {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      background: $blue;
      padding: 10px 35px;
      transition: all 0.2s;
      margin-bottom: 6px;

      &:hover {
        opacity: 0.95;
        transition: all 0.5s;
      }

      &:before {
        position: absolute;
        content: "";
        top: 22px;
        right: 30px;
        transform: translateY(-50%);
        background: url("../img/icon/slide-right-white.svg") center no-repeat;
        background-size: contain;
        width: 19px;
        height: 22px;
        transition: all 300ms ease-in;
      }

      h3 {
        margin-bottom: 0;
      }
    }

    .active_block {
      .title_block {
        opacity: 0.95;

        &:before {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }

    .info {
      display: none;

      .info-text-wrap {
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover {
          background: $lightblue2;

          & .info-text span {
            background: $lightblue2;
          }

          & .info-btn {
            background: $lightblue2;
          }

          & .info-btn .mbtn2 {
            background: $lightblue2;
          }
        }
      }

      .info-text {
        margin-left: 60px;
        position: relative;
        width: 80%;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 16px;

        span {
          position: relative;
          display: inline-block;
          z-index: 2;
          background-color: $white;
          padding-right: 5px;

          a:hover {
            text-decoration: underline;
          }

          &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-image: linear-gradient(to right, $red2 0%, $blue 100%);
            border-radius: 50%;
            background-size: cover;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
          }

          &:after {
            width: 20px;
            height: 20px;
            position: absolute;
            content: "";
            border-radius: 50%;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            background: url("../img/icon/garant4.svg") center center no-repeat;
            background-size: 65%;
          }
        }
      }

      .info-btn {
        width: 20%;
        text-align: center;
        position: relative;
        background: $white;
        align-self: center;
        z-index: 1;

        &:before {
          display: inline-block;
          content: "";
          height: 1px;
          width: 600px;
          border-bottom: 1px dashed #a7a7a7;
          top: 50%;
          transform: translateY(-50%);
          left: -590px;
          position: absolute;
          z-index: 0;
        }

        .mbtn2 {
          color: $black;
          border: none;
          padding: 0;

          &:hover {
            background: transparent;
            box-shadow: none;
          }
        }

        .mbtn2 {
          display: inline-block;
          font-size: 16px;
          padding: 5px 15px;
          background: $white;
          position: relative;
          cursor: pointer;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -20px;
            width: 25px;
            height: 25px;
            background: url("../img/icon/right-arrow-red.svg") center no-repeat;
            background-size: contain;
          }
        }
      }
    }

    .db {
      display: block;

      .info {
        display: block;
      }
    }
  }

  .accordion {
    .b5bgc1 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center 0;
    }

    .b5bgc2 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -50px;
    }

    .b5bgc3 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -100px;
    }

    .b5bgc4 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -150px;
    }

    .b5bgc5 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -200px;
    }

    .b5bgc6 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -250px;
    }

    .b5bgc7 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -300px;
    }

    .b5bgc8 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -350px;
    }

    .b5bgc9 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -400px;
    }

    .b5bgc10 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -450px;
    }

    .b5bgc11 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -500px;
    }

    .b5bgc12 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -550px;
    }

    .b5bgc13 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -600px;
    }

    .b5bgc14 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -650px;
    }

    .b5bgc15 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -700px;
    }

    .b5bgc16 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -750px;
    }

    .b5bgc17 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -800px;
    }

    .b5bgc18 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -850px;
    }

    .b5bgc19 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -900px;
    }

    .b5bgc20 {
      background: url("../img/pricefon.jpg") top center no-repeat;
      background-position: center -950px;
    }
  }
}

.preimblock {
  background: url("../img/5.jpg") center no-repeat fixed;
  background-size: cover;
  margin-top: 60px;

  &-wrap {
    background-color: rgba(0, 0, 0, 0.6);
    padding-top: 70px;
    padding-bottom: 100px;
    color: $white;
  }

  .zag {
    text-transform: uppercase;
  }

  &-b {
    width: 33.33%;
  }

  &-bots {
    margin-left: 16.5%;
  }

  .preim {
    width: 330px;
    height: 205px;
    padding: 60px 15px 50px 15px;
    position: relative;
    margin: auto;
    border: 1px solid $white;
    margin-top: 70px;

    &-zag {
      font-size: 20px;
      font-weight: 700;
    }

    &-text {
      padding-top: 8px;
      font-size: 16px;
    }

    &-img {
      position: absolute;
      left: 20px;
      top: -45px;
      max-width: 150px;

      img {
        max-width: 100%;
        max-height: 94px;
      }
    }
  }
}

.cartablock {
  > .container {
    display: flex;
    justify-content: space-evenly;
  }

  &-card {
    padding-top: 20px;
    min-width: 320px;

    div {
      position: relative;
    }

    &-zag {
      font-weight: 700;
      font-size: 20px;
      padding-left: 2px;
    }

    &-addr {
      font-size: 18px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 25px;

      &:before {
        position: absolute;
        width: 16px;
        height: 16px;
        content: "";
        top: 50%;
        left: 1px;
        transform: translateY(-50%);
        background: url("../img/icon/map.svg") center no-repeat;
        background-size: contain;
      }
    }

    &-tel {
      font-weight: 700;
      font-size: 20px;
      padding-left: 25px;

      &:before {
        position: absolute;
        width: 16px;
        height: 16px;
        content: "";
        top: 50%;
        left: 1px;
        transform: translateY(-50%);
        background: url("../img/icon/phone-blue.svg") center no-repeat;
        background-size: contain;
      }
    }

    &-vrrab {
      font-size: 18px;
      margin-top: 5px;
      padding-left: 25px;

      &:before {
        position: absolute;
        width: 16px;
        height: 16px;
        content: "";
        top: 50%;
        left: 1px;
        transform: translateY(-50%);
        background: url("../img/icon/time.svg") center no-repeat;
        background-size: contain;
      }
    }

    &-zopen {
      font-size: 18px;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 5px;
      padding-left: 25px;
      color: $blue;

      &:before {
        position: absolute;
        width: 16px;
        height: 16px;
        content: "";
        top: 50%;
        left: 1px;
        transform: translateY(-50%);
        background: url("../img/icon/pen2.svg") center no-repeat;
        background-size: contain;
      }
    }

    &-btnwrap {
      max-width: 320px;
      padding: 20px;
      margin-top: 10px;
      text-transform: uppercase;
      cursor: pointer;
      background-color: $red;
      color: white;
      font-size: 14px;
      position: relative;
      clip-path: polygon(0 0, 0 100%, 100% 100%, 87% 0%);

      &-btn {
        display: inline-block;
        position: relative;
        padding-right: 30px;
        font-weight: 500;

        &:before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background: url("../img/icon/arrow-right-white.svg") center no-repeat;
          background-size: contain;
        }
      }

      &-abs {
        position: absolute;
        background: $white;
        padding: 10px 20px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        display: none;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

        a {
          font-weight: 700;
          display: inline-block;
          position: relative;
          padding-right: 30px;

          &:hover {
            color: $blue;
          }

          &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            right: 0px;
            transform: translateY(-50%);
            background: url("../img/icon/right-arrow-red.svg") center no-repeat;
            background-size: contain;
          }
        }
      }

      &:hover {
        .cartablock-card-btnwrap-abs {
          display: block;
        }
      }
    }
  }

  &-carta {
    margin-top: 60px;

    iframe {
      width: 100%;
      height: 400px;
      border: none;
    }
  }
}

.footer {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 60px;
  border-top: 1px solid #ccc;

  &-logo {
    max-width: 150px;
    margin-bottom: 10px;
  }

  &-copyright {
    font-size: 13px;
  }

  &-menu {
    a {
      display: block;
      margin-bottom: 5px;
      font-size: 16px;
      transition: all 0.5s;

      &:hover {
        color: $blue;
        transition: all 0.5s;
      }
    }
  }
}

.fancybox-slide {
  background-color: rgba(0, 0, 0, 0.67);
}

/* GOOGLE RATE */
.rating-wraper {
  font-size: 15px;
}

.rating-box {
  margin-right: 10px;
  font-size: 20px;
}

.rating-astimates {
  margin-left: 10px;
}

.rating-overwrite {
  margin-top: 10px;
}

.redStar {
  color: $red;
}

/* GOOGLE RATE END */
.background-cover {
  background-size: cover !important;
}

.review {
  height: 150px;
  max-height: 150px;

  & img {
    height: 120px;
  }
}

.mt-90 {
  margin-top: 90px;
}

.vacancy-block {
  margin-top: 140px;
}

.vacancy__item_more {
  color: #28a9e5;
  text-decoration: revert;
}

.vacancy__item__button button {
  border-top-left-radius: 12px 50%;
  border-top-right-radius: 12px 50%;
  border-bottom-right-radius: 12px 50%;
  border-bottom-left-radius: 12px 50%;
  background: hsla(0, 0%, 100%, 0);
  border: 1.5px solid #28a9e5;
  font: 14px/14px Roboto;
  color: #28a9e5;
  cursor: pointer;
  padding: 10px 20px;
}

.vacancy {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-basis: 350px;
}

.vacancy__item button {
  border-top-left-radius: 12px 50%;
  border-top-right-radius: 12px 50%;
  border-bottom-right-radius: 12px 50%;
  border-bottom-left-radius: 12px 50%;
  background: hsla(0, 0%, 100%, 0);
  border: 1.5px solid #28a9e5;
  font: 14px/14px Roboto;
  color: #28a9e5;
  cursor: pointer;
  padding: 10px 20px;
}

.vacancy__item__line {
  padding-bottom: 25px;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 20px;
}

.vacancy__item_a {
  text-decoration: none;
  font-size: 28px;
  display: block;
  margin-bottom: 20px;
  color: #043ba6;
}

.button_vacancy {
  text-decoration: none;
  border-top-left-radius: 12px 50%;
  border-top-right-radius: 12px 50%;
  border-bottom-right-radius: 12px 50%;
  border-bottom-left-radius: 12px 50%;
  background: hsla(0, 0%, 100%, 0);
  border: 1.5px solid #28a9e5;
  font: 14px/14px Roboto;
  color: #28a9e5;
  cursor: pointer;
  padding: 10px 20px;
}

#vacancy {
  margin-top: 140px;
}

.vacancy__item__button a {
  color: #28a9e5;
}

.solo-punkt {
  display: inline-block;

  a, span {
    display: inline-block;
    font-size: 16px;
    color: $black;
    margin-left: 13px;
    margin-right: 13px;
    position: relative;
    transition: all 0.5s;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      width: 0%;
      height: 2px;
      background: $blue;
      bottom: -2px;
      left: 0;
      transform: rotateX(90deg);
      transition: all 0.5s;
    }

    &:hover {
      transition: all 0.5s;
      text-decoration: none;
      color: $blue;

      &:before {
        transform: rotateX(0);
        transition: all 0.5s;
        width: 100%;
      }
    }
  }
}

@import "./modal.scss";
@import "./remontblock.scss";
@import "./seo.scss";
@import "./media.scss";
@import "./slick.scss";
@import "./mango.scss";